import {
  GetLinkForDownloadTomIiFilesRequest,
  GetLinkForDownloadTomIiFilesResponse,
  GetTomsIiFileListRequest,
  GetTomsIiFileListResponse,
  SendTomsIiFileListRequest,
  SendTomsIiFileListResponse
} from './archiveExportApi.types'
import { tomIiApi } from '../tomApi'


export const archiveExportIiApi = tomIiApi.injectEndpoints({
  endpoints: (build) => ({
    getTomsIiFileList: build.query<GetTomsIiFileListResponse, GetTomsIiFileListRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-ii/download/get-file-list`,
        method: 'GET',
        params
      }),
      providesTags: [{ type: 'TomIi', id: 'ARCHIVE_TOMS' }],
    }),
    sendTomsIiFileList: build.mutation<SendTomsIiFileListResponse, SendTomsIiFileListRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/tom-ii/download/create-download-link`,
        method: 'POST',
        body,
      }),
    }),
    getLinkForDownloadTomIiFiles: build.query<GetLinkForDownloadTomIiFilesResponse, GetLinkForDownloadTomIiFilesRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom-ii/${id}/create-download-link`,
        method: 'GET',
        params
      }),
      providesTags: [
        'TomIi',
        { type: 'TomIi', id: 'UPLOAD' },
        { type: 'TomIi', id: 'DELETE' },
        { type: 'TomIi', id: 'UPLOAD_MASS' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomsIiFileListQuery,
  useSendTomsIiFileListMutation,
  useLazyGetLinkForDownloadTomIiFilesQuery,
} = archiveExportIiApi
