import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Menu, MenuItem } from '@mui/material'
import { MouseEvent, useState } from 'react'

import { StyledIconButton } from '../MemberRow'
import { StyledDivider, StyledTyporapy } from './MembersMenu.styles'
import { MembersMenuProps } from './MembersMenu.types'

export const MembersMenu = ({
  onFirstItemClick,
  onSecondItemClick,
  firstItemTitle,
  secondItemTitle,
  $color,
}: MembersMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleFirstItemClick = () => {
    onFirstItemClick()
    setAnchorEl(null)
  }

  const handleSecondItemClick = () => {
    onSecondItemClick()
    setAnchorEl(null)
  }

  return (
    <>
      <StyledIconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        $color={$color}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize='small' color='primary' />
      </StyledIconButton>
      <Menu
        id='menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '5px',
          },
        }}
      >
        <MenuItem onClick={handleFirstItemClick}>
          <CreateIcon color='secondary' fontSize='medium' />
          <StyledTyporapy variant='body2'>{firstItemTitle}</StyledTyporapy>
        </MenuItem>
        <StyledDivider />
        <MenuItem onClick={handleSecondItemClick}>
          <DeleteIcon color='secondary' fontSize='medium' />
          <StyledTyporapy variant='body2'>{secondItemTitle}</StyledTyporapy>
        </MenuItem>
      </Menu>
    </>
  )
}
