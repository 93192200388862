import DownloadingIcon from '@mui/icons-material/Downloading'
import { Box, Button, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'

import { tomSelector } from '@store/slices/documentsPages/tom'

import DownloadFilesIcon from '../../../../assets/icons/DownloadFilesIcon'
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { AttachmentsPageLegendProps } from './AttachmentsPageLegend.types'

export const AttachmentsPageLegend: FC<AttachmentsPageLegendProps> = ({ title, onUploadAttachmentsClick }) => {
  const dispatch = useAppDispatch()

  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled = tom?.features.annulment.annulled

  const onDownloadAttachmentsClick = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'exportAttachments' }))
  }

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1.5 }}>
      <Box>
        <Typography variant='body1'>{title}</Typography>
      </Box>

      <Stack direction='row' alignItems='center' spacing={1.5}>
        {!isAnnulled && (
          <Button onClick={onUploadAttachmentsClick} size='small' startIcon={<DownloadingIcon />}>
            Загрузить
          </Button>
        )}
        <Button onClick={onDownloadAttachmentsClick} size='small' startIcon={<DownloadFilesIcon />}>
          Скачать архив с файлами
        </Button>
      </Stack>
    </Stack>
  )
}
