import React from 'react'
import { RdCardProps } from './RdCard.types'
import Divider from '../../../../components/Divider'
import { Box, Stack, Typography } from '@mui/material'
import { RdCardText, RdCardTooltip, StyledRdCard, StyledRdCardContent, StyledRdCardHeader } from './styles'
import Button from '../../../../components/Button'
import EditIcon from '@mui/icons-material/Edit'

const RdCard: React.FC<RdCardProps> = ({ data, onClick, isPpd, onEditPpdClick, disablePpdEdit }) => {
  const { id, fullName, mark, description } = data

  return (
    <Box style={{ position: 'relative', width: '100%' }}>
      <StyledRdCard onClick={() => onClick(id)}>
        <StyledRdCardHeader
          title={
            <Box>
              <Typography variant='subtitle1' lineHeight='135%' className='ellipsisText'>{mark}</Typography>
            </Box>
          }
          subheader={
            <Box>
              <Typography variant='body2' className='ellipsisText'>{fullName}</Typography>
            </Box>
          }
          action={
            isPpd && !disablePpdEdit
              ? (
                <Button
                  onClick={(e) => onEditPpdClick?.(e, id)}
                  variant='text'
                  icon
                >
                  <EditIcon fontSize='medium' />
                </Button>
              )
              : null
          }
        />

        <Divider />

        <StyledRdCardContent>
          {description
            ? (
              <Stack spacing={0.5}>
                <Box>
                  <RdCardTooltip variant='body2'>Примечание:</RdCardTooltip>
                </Box>
                <Box>
                  <RdCardText variant='body1' className='ellipsisText'>{description}</RdCardText>
                </Box>
              </Stack>
            )
            : null
          }
        </StyledRdCardContent>
      </StyledRdCard>
    </Box>
  )
}

export default RdCard