import styled from 'styled-components'
import { IconButton, Button, Stack } from '@mui/material'

export const ProjectAgreementFormDeleteButton = styled(IconButton)`
  border-radius: 6px;
  height: 36px;

  &.MuiIconButton-root.Mui-disabled {
    background-color: #0000001f;
  }
  
  &,
  &:hover {
    background-color: ${props => props.theme.palette.error.main};
  }
  
  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.text.light};
  }
`
export const ArrowBackButton = styled(Button)`
  background-color: ${props => props.theme.palette.bg.shades};
  padding: 5px;
  border-radius: 6px;
  min-width: 40px;

  &:hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`
export const SchemaContainer = styled(Stack)`
  max-width: 100%;
  padding: 20px;
  gap: 12px;
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 16px;
  text-align: start;
`

export const StyledButton = styled(Button)`
  font-size: 13px;
  height: 38px;
  width: min-content;
  align-self: flex-end;
`