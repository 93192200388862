import { UseRenderAccordionDetailsProps, UseRenderAccordionDetailsResponse } from './useRenderAccordionDetails.types'
import { ChangeEvent, ReactNode } from 'react'
import { ProjectPhase } from '../../../../../../../../types/project'
import { useFormikContext } from 'formik'
import { ExportArchiveFormData } from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { Stack, Typography } from '@mui/material'
import { AccordionSubCheckbox } from '../../styles'
import { theme } from '../../../../../../../../styles/theme'
import { ExportArchiveTomItem } from '../../../ExportArchiveTomItem'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'
import { OverflowTextNew } from '@components/OverflowTextNew'

export const useRenderAccordionDetails = ({
  object,
  pd,
  ii,
  ird,
  objectIndex,
  pdIndex,
  iiIndex,
  irdIndex,
  setCheckedRd,
  getUpdatedSelectedRdToms,
  deleteTomsFromSelected,
}: UseRenderAccordionDetailsProps): UseRenderAccordionDetailsResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { values: formValues, setFieldValue } = useFormikContext<ExportArchiveFormData>()

  // Rd
  const rdAccordionDetails = object?.rdList?.map((rd, rdIndex) => {
    if (rd.hideByFilter) return

    return (
      <Stack pl={3} key={rd.name}>
        <AccordionSubCheckbox
          version='checkbox'
          name={`rdFileList[${objectIndex}].rdList[${rdIndex}].selected`}
          checkboxData={{
            label: (<OverflowTextNew variant='subtitle2' fontWeight={400} color={theme.palette.text.dark}>{rd.name}</OverflowTextNew>),
            checked: formValues.rdFileList[objectIndex!]?.rdList[rdIndex]?.selected,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              setCheckedRd(rd, objectIndex!, rdIndex, e.target.checked)

              e.target.checked
                ? setFieldValue('selectedRdToms', [...formValues.selectedRdToms, ...getUpdatedSelectedRdToms(rd, objectIndex!, rdIndex, e.target.checked)])
                : deleteTomsFromSelected(formValues.selectedRdToms, getUpdatedSelectedRdToms(rd, objectIndex!, rdIndex, e.target.checked))

              if (!e.target.checked) {
                setFieldValue(
                  `rdFileList[${objectIndex}].selected`,
                  false
                )
              }
            },
          }}
        />

        <Stack pl={2}>
          {rd?.tomList?.map((tom, tomIndex) => {
            if (tom.selected || tom.hideByFilter) return

            return (
              <ExportArchiveTomItem
                rdTom={tom}
                objectIndex={objectIndex!}
                rdIndex={rdIndex}
                tomIndex={tomIndex}
                key={tom.tomId}
              />
            )
          })}
        </Stack>
      </Stack>
    )
  })


  // Pd
  const pdAccordionDetails = (
    <Stack pl={4}>
      {pd?.tomList?.map((tom, tomIndex) => {
        if (tom.selected || tom.hideByFilter) return

        return (
          <ExportArchiveTomItem
            pdTom={tom}
            pdIndex={pdIndex!}
            tomIndex={tomIndex}
            key={tom.tomId}
          />
        )
      })}
    </Stack>
  )


  // Ii
  const iiAccordionDetails = (
    <Stack pl={4}>
      {ii?.tomList?.map((tom, tomIndex) => {
        if (tom.selected || tom.hideByFilter) return

        return (
          <ExportArchiveTomItem
            iiTom={tom}
            iiIndex={iiIndex!}
            tomIndex={tomIndex}
            key={tom.tomId}
          />
        )
      })}
    </Stack>
  )


  // Ird
  const irdAccordionDetails = (
    <Stack pl={4}>
      {ird?.tomList?.map((tom, tomIndex) => {
        if (tom.selected || tom.hideByFilter) return

        return (
          <ExportArchiveTomItem
            irdTom={tom}
            irdIndex={irdIndex!}
            tomIndex={tomIndex}
            key={tom.tomId}
          />
        )
      })}
    </Stack>
  )


  // Common
  const accordionDetails: Record<ProjectPhase, ReactNode> = {
    'Рабочая документация': rdAccordionDetails,
    'Проектная документация': pdAccordionDetails,
    'Инженерные изыскания': iiAccordionDetails,
    'Сбор исходных данных': irdAccordionDetails,
  }

  return {
    accordionDetails: accordionDetails[selectedProjectPhase],
  }
}
