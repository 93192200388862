import { ChangeEvent, useEffect, useRef, useState } from "react"
import { AutomaticStampPlacementProps, PagesField, PagesFieldData, SelectedPages, SelectField } from "."
import { FormWrapper } from "../CustomStampForm"
import { MenuItem, Stack, Typography } from "@mui/material"
import { useTypedSelector } from "@store/store"
import { documentPagesSelector, pagesForStampSelector } from "@store/slices/pdfViewer/selectors/pdfViewer.selectors"

const parsePageRanges = (value: string) => {
  const cleanedInput = value.replace(/[^0-9,\-]/g, '')
  const result = new Set<number>()
  let isError = false
  const parts = cleanedInput.split(',')

  for (const part of parts) {
    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number)

      if (!isNaN(start) && !isNaN(end)) {
        if (start > end) {
          isError = true
          break
        }

        for (let i = start; i <= end; i++) {
          if (i > 0) result.add(i);
        }
      }
    } else {
      const number = Number(part)
      if (!isNaN(number) && number > 0) {
        result.add(number)
      }
    }
  }

  return { isError, values: isError ? [] : Array.from(result).sort((a, b) => a - b) }
}

export const AutomaticStampPlacement = ({ automaticPlacement }: AutomaticStampPlacementProps) => {

  const documentPages = useTypedSelector(documentPagesSelector)
  const pagesForStamp = useTypedSelector(pagesForStampSelector)
  const [selectedPages, setSelectedPages] = useState<SelectedPages>('Все')
  const [pagesValue, setPagesValue] = useState<PagesFieldData>({ value: '', error: { isError: true, message: 'Поле не может быть пустым' } })
  const [contentHeight, setContentHeight] = useState<number>(0)

  const contentRef = useRef<HTMLDivElement>(null)
  //const contentHeight = contentRef.current?.scrollHeight

  useEffect(() => {
    setContentHeight(selectedPages === 'Все' ? 61 : 122)
  }, [selectedPages])

  const changePages = (value: string) => {
    if (value === '') {
      setPagesValue({ value, error: { isError: true, message: 'Поле не может быть пустым' } })
    } else {
      const { isError, values } = parsePageRanges(value)
      setPagesValue({ value, error: { isError, message: isError ? 'Некорректно задан диапазон страниц' : '' } })
      console.log(values)
    }
  }
  
  return (
    <FormWrapper collapse={automaticPlacement} ref={contentRef} height={automaticPlacement ? contentHeight : 0}>
      <Stack direction='column' spacing={1}>
        <Typography fontSize={14}>Страницы</Typography>
        <SelectField
          value={selectedPages}
          onChange={(e) => setSelectedPages(e.target.value as SelectedPages)}>
          <MenuItem value={'Все'}>Все</MenuItem>
          <MenuItem value={'Некоторые'}>Некоторые</MenuItem>
        </SelectField>
        {selectedPages === 'Некоторые' &&
          <PagesField
            variant="outlined"
            value={pagesValue.value}
            error={pagesValue.error.isError}
            helperText={pagesValue.error.message}
            placeholder='Например 1- 5, 8, 11 - 13'
            onChange={(e: ChangeEvent<HTMLInputElement>) => changePages(e.target.value)}
          />
        }
      </Stack>
    </FormWrapper>
  )
}