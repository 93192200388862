import React, { useCallback } from 'react'
import { FormFieldProps } from './FieldForm.types'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { Box, FormControlLabel, IconButton, InputAdornment, TextField } from '@mui/material'
import {
  DatePickerRenderInput,
  ProjectFormTextField,
  SmallFormTextField,
  StyledCheckbox,
  StyledSelectField,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTitle,
} from './styles'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Visibility as VisibilityIcon } from '@mui/icons-material'
import { PasswordField } from './components/PasswordField'

const FieldForm: React.FC<FormFieldProps> = ({
  version,
  title,
  maxLength,
  InputProps,
  fieldProps,
  dateFieldProps,
  onBlur,
  dataValue,
  checkboxData,
  onDataChange,
  maxDate,
  children,
  className,
  ...props
}) => {
  const { t } = useTranslation()
  const [{ onBlur: onFieldBlur, ...field }, { touched, error }] = useField({
    name: props.name,
    ...fieldProps,
  })

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onFieldBlur?.(e)
      onBlur?.(e)
    },
    [onBlur, onFieldBlur],
  )

  const isError = touched && !!error

  switch (version) {
    case 'profile':
      return (
        <label style={{ textAlign: 'left' }} className={className}>
          <StyledTextFieldTitle mb={0.75} variant='tooltip' pl={2}>
            {title}
          </StyledTextFieldTitle>
          <StyledTextField
            {...field}
            InputProps={{ ...InputProps, disableUnderline: true }}
            fullWidth
            variant='filled'
            size='small'
            helperText={isError ? t(error as string, { maxLength }) : undefined}
            onBlur={handleBlur}
            error={isError}
            {...props}
          />
        </label>
      )

    case 'project':
    case 'rd':
    case 'objects':
    case 'doc':
      return (
        <ProjectFormTextField
          {...field}
          InputProps={{ ...InputProps, disableUnderline: true }}
          fullWidth
          variant='filled'
          size='small'
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          className={className}
          {...props}
        />
      )

    case 'date':
      return (
        <DesktopDatePicker
          mask='__.__.____'
          maxDate={maxDate}
          value={dataValue}
          onChange={onDataChange!}
          {...dateFieldProps}
          components={{
            OpenPickerIcon: CalendarTodayIcon,
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              helperText={isError ? t(error as string, { maxLength }) : undefined}
              {...props}
              {...params}
              onBlur={handleBlur}
              error={!!isError}
              inputProps={{
                ...params.inputProps,
                ...props.inputProps,
                placeholder: props.inputProps?.placeholder || 'ДД.ММ.ГГГГ',
                autoComplete: 'off',
              }}
            />
          )}
          className={className}
        />
      )

    case 'select':
      return (
        <StyledSelectField
          select
          {...field}
          InputProps={{ ...InputProps, disableUnderline: true }}
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          className={className}
          {...props}
        >
          {children}
        </StyledSelectField>
      )

    case 'small':
      return (
        <SmallFormTextField
          {...field}
          InputProps={{ ...InputProps, disableUnderline: true }}
          fullWidth
          variant='filled'
          size='small'
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          className={className}
          {...props}
        />
      )

    case 'checkbox':
      return (
        <FormControlLabel
          onClick={checkboxData?.onLabelClick}
          control={
            <StyledCheckbox
              {...field}
              style={{ marginRight: 0 }}
              checked={!!field.value}
              onChange={checkboxData?.onChange}
              inputProps={{
                onBlur: handleBlur,
                ...props.inputProps,
              }}
              icon={checkboxData?.checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            />
          }
          label={<Box>{checkboxData?.label}</Box>}
          className={className}
          sx={{ mr: 0 }}
          disabled={props.disabled}
        />
      )

    case 'switch':
      return (
        <FormControlLabel
          control={
            <StyledSwitch
              {...field}
              checked={!!checkboxData?.checked}
              // checked={!!field.value}
              onChange={checkboxData?.onChange}
              inputProps={{
                onBlur: handleBlur,
                ...props.inputProps,
              }}
            />
          }
          label={checkboxData?.label}
          className={className}
        />
      )

    case 'password':
      return <PasswordField field={field} {...props} />

    default:
      return (
        <TextField
          {...field}
          onBlur={handleBlur}
          error={isError}
          helperText={isError ? t(error as string) : undefined}
          className={className}
          {...props}
        />
      )
  }
}

export default FieldForm
