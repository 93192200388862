import { useMemo } from "react"
import { useFormikContext } from "formik"
import { InternalAgreementAutocompleteValues, StyledAutocomplete, StyledLabel } from "../InternalAgreementForm"
import { PersonAutocompleteFieldsProps } from "./PersonAutocompleteFields.types"
import { Box } from "@mui/material"

export const PersonAutocompleteFields = ({ availablePersons, formMode, width }: PersonAutocompleteFieldsProps) => {

  const { values, setValues } = useFormikContext<InternalAgreementAutocompleteValues>() ?? {}

  const employeePosition = useMemo(() => {
    if (values.name) {
      const filteredEmployees = availablePersons.filter((person) => person.name === values.name)
      if (filteredEmployees.length === 1) {
        const uniquePosition = filteredEmployees[0].position
        setValues({ ...values, position: uniquePosition })
        return [uniquePosition]
      } else {
        return filteredEmployees.map(person => person.position)
      }
    }
    const employeePositionList: string[] = availablePersons.map((person) => person.position)
    return Array.from(new Set(employeePositionList))
  }, [availablePersons, values.name, setValues])

  const employeeNames = useMemo(() => {
    return availablePersons.map((person) => person.name);
  }, [availablePersons]);

  const filteredEmployeeNames = useMemo(() => {
    if (values.position) {
      const filteredEmployees = availablePersons.filter((person) => person.position === values.position);
      if (filteredEmployees.length === 1) {
        const uniqueName = filteredEmployees[0].name;
        setValues({ ...values, name: uniqueName });
        return [uniqueName];
      } else {
        return employeeNames.filter((name) =>
          availablePersons.some((person) => person.name === name && person.position === values.position)
        );
      }
    }
    return employeeNames;
  }, [employeeNames, values.position, setValues]);

  return (
    <>
      <StyledAutocomplete
        fieldName='position'
        placeholder="Должность участника*"
        disabled={formMode === 'edit'}
        noOptionsText="Нет доступных участников"
        $width={width}
        customRenderOption={(props, option) => (
          <Box component="li" {...props} key={option.key}>
            <StyledLabel variant="body2">
              {option.label}
            </StyledLabel>
          </Box>
        )}
        data={employeePosition?.map((position, index) => ({
          value: position,
          label: position,
          key: index
        })) || []} />

      <StyledAutocomplete
        fieldName='name'
        placeholder="Введите ФИО*"
        disabled={formMode === 'edit'}
        noOptionsText="Нет доступных участников"
        $width={width}
        customRenderOption={(props, option) => (
          <Box component="li" {...props} key={option.key}>
            <StyledLabel variant="body2">
              {option.label}
            </StyledLabel>
          </Box>
        )}
        data={filteredEmployeeNames?.map((name, index) => ({
          value: name,
          label: name,
          key: index
        })) || []} />
    </>
  )
}