import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

type AcceptedValue = string | boolean | number | null

export interface QueryHandler {
  get: <T = string> (key: string) => T | null
  set: (key: string, value: AcceptedValue, history?: boolean, overwrite?: boolean) => void
  remove: (key: string) => void
}

/**
 * Хук позволяющий взаимодействовать с query параметрами.
 */
export default function useQuery(callback?: (handler: QueryHandler) => any): QueryHandler {
  const location = useLocation()
  const queryRef = useRef(new URLSearchParams(location.search))

  useEffect(() => {
    queryRef.current = new URLSearchParams(location.search)
  }, [location.search])

  const updateURL = useCallback(
    (newQuery: URLSearchParams, history?: boolean) => {
      const arr = [window.location.origin + window.location.pathname, newQuery.toString()]
      const url = arr.filter(Boolean).join('?')

      history
       ? window.history.pushState({ path: url }, '', url)
       : window.history.replaceState({ path: url }, '', url);
       queryRef.current = newQuery
    },
    []
  )

  const get = useCallback(
    (key: string) => {
      return queryRef.current.get(key) as any
    },
    []
  )

  const set = useCallback(
    (key: string, value: AcceptedValue, history?: boolean, overwrite: boolean = true) => {
      const newQuery = new URLSearchParams(queryRef.current.toString())
      if (overwrite) {
        newQuery.set(key, String(value))
      } else {
        newQuery.append(key, String(value))
      }
      updateURL(newQuery, history)
    },
    [updateURL]
  )

  const remove = useCallback(
    (key: string) => {
      const newQuery = new URLSearchParams(queryRef.current.toString())
      newQuery.delete(key)
      updateURL(newQuery)
    },
    [updateURL]
  )

  const handler = {
    get,
    set,
    remove
  }

  useEffect(() => {
    if (callback) callback(handler)
  }, [callback, handler])

  return handler
}

