import { useEffect, useMemo, useRef, useState } from "react"
import { CustomStampAutocomplete, CustomStampCheckbox, CustomStampFormProps, CustomStampTextField, FormWrapper, VisibilitySettings } from "."
import { useAppDispatch, useTypedSelector } from "@store/store"
import { stampSettingsSelector } from "@store/slices/pdfViewer/selectors/pdfViewer.selectors"
import { setStampSettings } from "@store/slices/pdfViewer/pdfViewer"
import { format as FnsFormat } from 'date-fns'
import { FormControlLabel, IconButton, Stack, TextField, Typography } from "@mui/material"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ElipsisText } from "@styles/global/ElipsisText"
import { useGetAllUserCompanyNamesQuery } from "@api/users"

const currentDate = FnsFormat(new Date(), 'dd.MM.yyyy')

export const CustomStampForm = ({ stampChangeMode, setIsValidFields }: CustomStampFormProps) => {
  const dispatch = useAppDispatch()
  const contentRef = useRef<HTMLDivElement>(null)
  const contentHeight = contentRef.current?.scrollHeight
  const stampSettings = useTypedSelector(stampSettingsSelector)
  const { data } = useGetAllUserCompanyNamesQuery({}, { skip: !stampChangeMode })
  const { data: allCompanies } = data || {}

  const { company, companyVisibility, name, nameVisibility, position, positionVisibility, date, signature, title, type } = stampSettings[0]

  const isValidTitleField = title !== ''
  const isValidCompanyField = !companyVisibility || (companyVisibility && company !== '')

  useEffect(() => {
    if (stampChangeMode) {
      const isValid = isValidTitleField && isValidCompanyField
      setIsValidFields(isValid)
    }
  }, [isValidCompanyField, isValidTitleField, stampChangeMode])


  const changeTitle = (value: string) => {
    let lines = value.split('\n')

    if (lines.length === 1 && lines[0].length > 25) {
      const firstLine = lines[0].slice(0, 25)
      const lastSpaceIndex = firstLine.lastIndexOf(' ')

      if (lastSpaceIndex !== -1) {
        lines = [
          firstLine.slice(0, lastSpaceIndex),
          lines[0].slice(lastSpaceIndex + 1),
        ]
      } else {
        lines = [firstLine, lines[0].slice(25)]
      }
    }

    lines = lines.slice(0, 2)
    dispatch(setStampSettings([{ ...stampSettings[0], title: lines.join('\n') }]))
  }

  const changeCompanyVisibility = () => {
    dispatch(setStampSettings([{ ...stampSettings[0], companyVisibility: !companyVisibility }]))
  }

  const changeCompany = (value: string) => {
    if (value !== company) {
      dispatch(setStampSettings([{
        ...stampSettings[0],
        company: value,
        position: '',
        name: '',
      }]))
    }
  }

  const changePositionVisibility = () => {
    dispatch(setStampSettings([{ ...stampSettings[0], positionVisibility: !positionVisibility }]))
  }

  const changePosition = (value: string) => {
    dispatch(setStampSettings([{ ...stampSettings[0], position: value }]))
  }

  const changeNameVisibility = () => {
    dispatch(setStampSettings([{ ...stampSettings[0], nameVisibility: !nameVisibility }]))
  }

  const changeName = (value: string) => {
    dispatch(setStampSettings([{ ...stampSettings[0], name: value }]))
  }

  const changeSignature = (value: boolean) => {
    dispatch(setStampSettings([{ ...stampSettings[0], signature: value }]))
  }

  const changeDate = (value: boolean) => {
    dispatch(setStampSettings([{ ...stampSettings[0], date: !value ? null : currentDate }]))
  }

  const companyEmployees = useMemo(() => {
    if (!allCompanies || allCompanies.length === 0) return []
    const filteredEmployees = allCompanies.filter(i => i.company === company)
    if (filteredEmployees.length > 0) {
      return filteredEmployees[0].employees
    } else return []
  }, [company, allCompanies])

  const employeePosition = useMemo(() => {
    if (name) {
      const filteredEmployees = companyEmployees.filter((person) => person.name === name)
      if (filteredEmployees.length === 1) {
        const uniquePosition = filteredEmployees[0].position || ''
        changePosition(uniquePosition)
        return [uniquePosition]
      } else {
        return filteredEmployees.map(person => person.position)
      }
    }
    const employeePositionList: string[] = companyEmployees.map((person) => person.position || '')
    return Array.from(new Set(employeePositionList))
  }, [companyEmployees, name])

  const filteredEmployeeNames = useMemo(() => {
    if (companyEmployees.length === 0) return []
    const employeeNames = companyEmployees.map((person) => person.name)
    if (position) {
      const filteredEmployees = companyEmployees.filter((person) => person.position === position)
      if (filteredEmployees.length === 1) {
        const uniqueName = filteredEmployees[0].name || ''
        changeName(uniqueName)
        return [uniqueName]
      } else {
        return employeeNames.filter((name) =>
          companyEmployees.some((person) => person.name === name && person.position === position)
        )
      }
    }
    return employeeNames
  }, [companyEmployees, position])

  return (
    <FormWrapper collapse={stampChangeMode} gap={1.25} ref={contentRef} height={stampChangeMode ? contentHeight : 0}>
      <CustomStampTextField
        variant="outlined"
        multiline
        maxRows={2}
        value={title}
        error={title === ''}
        placeholder={`Укажите заголовок штампа ${'\n'}Нажмите Enter для переноса строки`}
        onChange={(e) => changeTitle(e.target.value)}
      />
      <VisibilitySettings direction='row'>
        <Typography>Компания</Typography>
        <IconButton onClick={changeCompanyVisibility}>
          {companyVisibility ? <VisibilityIcon color="primary" fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}
        </IconButton>
      </VisibilitySettings>
      {companyVisibility &&
        <CustomStampAutocomplete
          blurOnSelect
          noOptionsText='Не найдено'
          clearText='Очистить'
          openText='Выбрать'
          closeText='Закрыть'
          value={company}
          onChange={(e, newValue: any) => changeCompany(newValue?.value || '')}
          renderInput={(params) => (
            <TextField
              {...params}
              error={company === ''}
              placeholder="Выберите значение"
            />
          )}
          renderOption={
            (props, option: any) => (
              <Stack component="li" {...props}>
                <ElipsisText>
                  {option.label}
                </ElipsisText>
              </Stack>
            )
          }
          options={allCompanies?.map(({ company }, index) => ({
            value: company,
            label: company,
            key: index
          })) || []
          } />}
      <VisibilitySettings direction='row'>
        <Typography>Должность</Typography>
        <IconButton onClick={changePositionVisibility}>
          {positionVisibility ? <VisibilityIcon color="primary" fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}
        </IconButton>
      </VisibilitySettings>
      {positionVisibility &&
        <CustomStampAutocomplete
          blurOnSelect
          noOptionsText='Не найдено'
          clearText='Очистить'
          openText='Выбрать'
          closeText='Закрыть'
          value={position}
          disabled={company === ''}
          onChange={(e, newValue: any) => changePosition(newValue?.value || '')}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Выберите значение"
            />
          )}
          renderOption={
            (props, option: any) => (
              <Stack component="li" {...props}>
                <ElipsisText>
                  {option.label}
                </ElipsisText>
              </Stack>
            )
          }
          options={employeePosition.map((position, index) => ({
            value: position,
            label: position,
            key: index
          }))
          } />}
      <VisibilitySettings direction='row'>
        <Typography>ФИО</Typography>
        <IconButton onClick={changeNameVisibility}>
          {nameVisibility ? <VisibilityIcon color="primary" fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}
        </IconButton>
      </VisibilitySettings>
      {nameVisibility &&
        <CustomStampAutocomplete
          blurOnSelect
          noOptionsText='Не найдено'
          clearText='Очистить'
          openText='Выбрать'
          closeText='Закрыть'
          value={name}
          disabled={company === ''}
          onChange={(e, newValue: any) => changeName(newValue?.value || '')}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Выберите значение"
            />
          )}
          renderOption={
            (props, option: any) => (
              <Stack component="li" {...props}>
                <ElipsisText>
                  {option.label}
                </ElipsisText>
              </Stack>
            )
          }
          options={filteredEmployeeNames.map((name, index) => ({
            value: name,
            label: name,
            key: index
          }))
          } />}
      <Stack direction='row' justifyContent='flex-start' alignItems='center' gap={4}>
        <FormControlLabel label='Подпись' control={
          <CustomStampCheckbox checked={signature} onChange={(e) => changeSignature(e.target.checked)} />
        } />
        <FormControlLabel label='Дата' control={
          <CustomStampCheckbox checked={!!date} onChange={(e) => changeDate(e.target.checked)} />
        } />
      </Stack>
    </FormWrapper>
  )
}