import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { FormPocketInnerAccordionProps } from './FormPocketInnerAccordion.types'
import {
  FormPocketAccordionDetails,
  FormPocketInnerAccordionSummary,
  StyledFormPocketAccordion,
} from './FormPocketInnerAccordion.styles'
import FieldForm from '../../../../../../components/FieldForm'
import { Stack, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { FormPocketDrawerFormData } from '../FormPocketDrawerForm'
import { theme } from '../../../../../../styles/theme'
import { FormPocketDrawerTomItem } from '../FormPocketDrawerTomItem'
import {
  GetUpdatedSelectedTomsArgs,
  SetCheckedRdGroupArgs,
  useAccordionCheckState
} from './hooks/useAccordionCheckState'
import { ProjectExportGroup, ProjectExportObject } from '../../../../../../api/projects/types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionSubCheckbox
} from '../../../../../ProjectInfo/components/ProjectFullExportDrawer/components/FullExportAccordion'
import { OverflowTextNew } from '@components/OverflowTextNew'

export const FormPocketInnerAccordion: FC<FormPocketInnerAccordionProps> = ({
  phase,
  object,
  objectIndex,
  group,
  groupIndex,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<FormPocketDrawerFormData>()
  const { objectList, groupList } = formValues

  const [expanded, setExpanded] = useState<boolean>(false)

  const accordionLabel = (phase === 'РД' ? object : group).name
  const accordionFieldName = phase === 'РД'
    ? `objectList[${objectIndex}].selected`
    : `groupList[${groupIndex}].selected`

  const accordionFieldChecked = phase === 'РД'
    ? objectList![objectIndex]?.selected
    : groupList![groupIndex]?.selected

  const setOpenedManually = () => {
    phase === 'РД'
      ? setFieldValue(`objectList[${objectIndex}].openedManually`, !expanded)
      : setFieldValue(`groupList[${groupIndex}].openedManually`, !expanded)
  }

  const onSummaryClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setExpanded(prevState => !prevState)
    setOpenedManually()
  }

  // set object accordion checkbox checked when all rd groups checked
  useEffect(() => {
    if (phase !== 'РД') return

    const filteredGroups = objectList[objectIndex].groups?.filter(group => !group.hideByFilter)

    if (filteredGroups?.length && filteredGroups.every(group => group.selected)) {
      setFieldValue(`objectList[${objectIndex}].selected`, true)
    }
  }, [phase === 'РД' && objectList[objectIndex].groups])

  // close and open this accordion depending on checked status
  useEffect(() => {
    if (phase === 'РД') {
      if (objectList[objectIndex]?.selected) {
        setExpanded(false)
        return
      }

      if (objectList[objectIndex]?.selected !== undefined && objectList[objectIndex]?.openedManually) {
        setExpanded(true)
      }
    } else {
      if (groupList[groupIndex]?.selected) {
        setExpanded(false)
        return
      }

      if (groupList[groupIndex]?.selected !== undefined && groupList[groupIndex]?.openedManually) {
        setExpanded(true)
      }
    }

  }, [phase === 'РД' ? objectList[objectIndex] : groupList[groupIndex]])

  const {
    onAccordionCheckboxChange,
    setCheckedRdGroup,
    getUpdatedSelectedToms,
    deleteTomsFromSelected,
    disableExpandIcon,
    accordionHideByFilter,
  } = useAccordionCheckState({
    phase,
    object: object as ProjectExportObject,
    group: group as ProjectExportGroup,
    objectIndex: objectIndex as number,
    groupIndex: groupIndex as number,
  })

  if (accordionHideByFilter) return null

  return (
    <StyledFormPocketAccordion
      expanded={expanded}
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
    >
      <FormPocketInnerAccordionSummary
        onClick={disableExpandIcon ? undefined : onSummaryClick}
        expandIcon={!disableExpandIcon && (
          <ExpandMoreIcon
            fontSize='medium'
            color='primary'
          />
        )}
      >
        <FieldForm
          version='checkbox'
          name={accordionFieldName}
          checkboxData={{
            label: (<OverflowTextNew variant='subtitle2'>{accordionLabel}</OverflowTextNew>),
            checked: accordionFieldChecked,
            onChange: (e: ChangeEvent<HTMLInputElement>) => onAccordionCheckboxChange(accordionFieldName, e),
            onLabelClick: (e) => e.stopPropagation(),
          }}
        />
      </FormPocketInnerAccordionSummary>

      <FormPocketAccordionDetails>
        {phase === 'РД'
          ? object.groups.map((group, innerGroupIndex) => (
            <Stack pl={4} key={`${phase}-object-${object.name}-group-${group.name}`}>
              <AccordionSubCheckbox
                version='checkbox'
                name={`objectList[${objectIndex}].groups[${innerGroupIndex}].selected`}
                checkboxData={{
                  label: (<OverflowTextNew variant='subtitle2' fontWeight={400} color={theme.palette.text.dark}>{group.name}</OverflowTextNew>),
                  checked: objectList[objectIndex]?.groups[innerGroupIndex].selected,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    const checked = e.target.checked

                    const dataForRdGroup: SetCheckedRdGroupArgs = {
                      group,
                      groupIndex: innerGroupIndex,
                      objectIndex,
                      checked
                    }
                    setCheckedRdGroup(dataForRdGroup)

                    const dataForToms: GetUpdatedSelectedTomsArgs = {
                      ...dataForRdGroup,
                      phase,
                    }

                    checked
                      ? setFieldValue('selectedToms', [...formValues.selectedToms, ...getUpdatedSelectedToms(dataForToms)])
                      : deleteTomsFromSelected(formValues.selectedToms, getUpdatedSelectedToms(dataForToms))

                    if (!checked) {
                      setFieldValue(`objectList[${objectIndex}].selected`, false)
                    }
                  },
                }}
              />

              <Stack pl={2}>
                {group.toms?.map((tom, innerTomIndex) => {
                  if (tom.selected || tom.hideByFilter) return

                  return (
                    <FormPocketDrawerTomItem
                      phase={phase}
                      tom={tom}
                      objectIndex={objectIndex!}
                      groupIndex={innerGroupIndex}
                      tomIndex={innerTomIndex}
                      key={`${phase}-object-${object.name}-group-${group.name}-tom-${tom.id}`}
                    />
                  )
                })}
              </Stack>
            </Stack>
          )
          )
          : (
            <Stack pl={2}>
              {group.toms?.map((tom, innerTomIndex) => {
                if (tom.selected || tom.hideByFilter) return

                return (
                  <FormPocketDrawerTomItem
                    phase={phase}
                    tom={tom}
                    groupIndex={groupIndex}
                    tomIndex={innerTomIndex}
                    key={`${phase}-group-${group.name}-tom-${tom.id}`}
                  />
                )
              })}
            </Stack>
          )
        }
      </FormPocketAccordionDetails>
    </StyledFormPocketAccordion>
  )
}
