import { Autocomplete, Stack, TextField, Checkbox } from "@mui/material";
import styled from "styled-components";

export const FormWrapper = styled(Stack) <{ collapse: boolean }>`
  height: ${props => props.collapse ? props.height + 'px' : '0px'};
  min-height: 0px;
  opacity: ${props => props.collapse ? 1 : 0.1};
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  visibility: ${props => props.collapse ? 'visible' : 'hidden'};
  text-align: start;
`

export const CustomStampTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    height: 55px;
    font-size: 14px;
    border-radius: 6px;
    padding: 0;

    & .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.text.dark + '1F'};
      border-width: 1px;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primary.main};
      border-width: 1px;
    }

    & .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${props => props.theme.palette.primary.main};
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${props => props.theme.palette.error.main};
    }
  }

  & .MuiOutlinedInput-input {
    padding: 5px 8px;
    height: auto !important;
  }
`
export const VisibilitySettings = styled(Stack)`
  align-items: center;
  justify-content: space-between;

  & .MuiTypography-root {
    font-size: 14px;
  }
`
export const CustomStampAutocomplete = styled(Autocomplete)`
  & .MuiOutlinedInput-root {
    padding: 0;
  }

  & .MuiAutocomplete-inputRoot {
    height: 32px;
    font-size: 14px;
    border-radius: 6px;

    & .MuiOutlinedInput-input {
      padding: 5px 0 5px 8px;
    }

    & .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.text.dark + '1F'};
      border-width: 1px;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primary.main};
      border-width: 1px;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${props => props.theme.palette.primary.main};
    }
    
    &.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.text.dark + '1F'};
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${props => props.theme.palette.error.main};
    }
  }
`
export const CustomStampCheckbox = styled(Checkbox)`
  padding: 6px;
  margin-left: 10px;

  & + .MuiTypography-root {
    font-size: 14px;
  }
`