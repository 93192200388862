import { Button, Stack, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from "styled-components";

export const SideBarWrapper = styled(Stack)`
  width: 330px;
  min-width: 330px;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 8px;
  padding: 10px;
`
export const StampButton = styled(Button) <{ border: boolean }>`
  height: auto;
  background-color: transparent;
  padding: 6px;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${props => props.border ? props.theme.palette.primary.main : 'transparent'};

  :hover {
    box-shadow: 0px 0px 5px 0px #bdbdbd;
    background-color: ${props => props.theme.palette.bg.gray};
  }
`
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 36px;
  margin: 0 6px 10px 6px;
`
export const StyledToggleButton = styled(ToggleButton)`
  padding: 7px;
  width: 100%;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid ${props => props.theme.palette.primaryBorderLight};
  background-color: transparent;
  color: ${props => props.theme.palette.primary.main};

  &.Mui-selected {
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
`
export const SettingsBlock = styled(Stack)`
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 8px;
  padding: 16px;
`
export const SettingBlockStyledSwitch= styled(Switch)`
  & .MuiSwitch-switchBase {
    &.Mui-checked {

      & .MuiSwitch-thumb {
        background-color: ${props => props.theme.palette.primary.main};
      }

      & + .MuiSwitch-track {
        background-color: ${props => props.theme.palette.legends.gray};
        opacity: 1;
      }
    }

    & .MuiSwitch-thumb {
      background-color: ${props => props.theme.palette.secondary.dark};
    }
  }

  & .MuiSwitch-track {
    background-color: ${props => props.theme.palette.secondary.gray};
    opacity: 0.6;
  }
`