import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilteredStamp, PdfVieweState, QRCode, SelectedStamp, Stamp, StampMode, StampSettings, StampTypes } from "./pdfViewer.types";
import { CustomStamp, ICustomStamp } from "@pages/DocView/components/StampRightSideBar";
import { format as FnsFormat } from 'date-fns'

const currentDate = FnsFormat(new Date(), 'dd.MM.yyyy')
const defaultStamps: FilteredStamp[] = [
  {
    type: 'agreed',
    data: CustomStamp({ date: currentDate, type: 'Согласовано' }),
    name: 'Штамп Согласовано'
  },
  {
    type: 'approved',
    data: CustomStamp({ date: currentDate, type: 'Утверждено' }),
    name: 'Штамп Утверждено'
  },
  {
    type: 'inProd',
    data: CustomStamp({ date: currentDate, type: 'В производство работ' }),
    name: 'Штамп В производство работ'
  },
]

const defaultStampSettings: StampSettings[] = [
  {
    title: 'Согласовано',
    date: currentDate,
    name: 'Фамилия И.О.',
    nameVisibility: true,
    position: 'Должность',
    positionVisibility: true,
    company: 'Компания',
    companyVisibility: true,
    signature: true,
    type: 'agreed',
    sourceTitle: 'Согласовано'
  },
  {
    title: 'Утверждено',
    date: currentDate,
    name: 'Фамилия И.О.',
    nameVisibility: true,
    position: 'Должность',
    positionVisibility: true,
    company: 'Компания',
    companyVisibility: true,
    signature: true,
    type: 'approved',
    sourceTitle: 'Утверждено'
  },
  {
    title: 'В производство работ',
    date: currentDate,
    name: 'Фамилия И.О.',
    nameVisibility: true,
    position: 'Должность',
    positionVisibility: true,
    company: 'Компания',
    companyVisibility: true,
    signature: true,
    type: "inProd",
    sourceTitle: 'В производство работ'
  }
]

let initialState: PdfVieweState = {
  filteredStamps: defaultStamps,
  stampSettings: defaultStampSettings,
  selectedStamp: null,
  stamps: [],
  saveTrigger: false,
  stampMode: 'stamp',
  isSelectedQRCode: false,
  qrCodeData: null,
  qrCodes: [],
  documentPages: null,
  pagesForStamp: []
}

const pdfViewersSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
/*     filterStamps: (state, { payload }: PayloadAction<StampTypes>) => {
      state.filteredStamps = state.filteredStamps.filter(s => s.type === payload)
    }, */
    setSelectedStamp: (state, { payload }: PayloadAction<SelectedStamp | null>) => {
      if (payload === null) {
        state.stampSettings = defaultStampSettings
      }
      state.selectedStamp = payload
    },
/*     changeStamp: (state, { payload }: PayloadAction<{ type: string }>) => {
      state.filteredStamps = [
        {
          data: CustomStamp({ date: currentDate, type: payload.type }),
          name: `Штамп ${payload.type}`,
          type: 'custom'
        }
      ]
    }, */
    setStampSettings: (state, { payload }: PayloadAction<StampSettings[]>) => {
      const { title, company, companyVisibility, date, name, nameVisibility, position, positionVisibility, signature, type, sourceTitle } = payload[0]
      state.stampSettings = [
        {
          title,
          company,
          companyVisibility,
          date,
          name,
          nameVisibility,
          position,
          positionVisibility,
          signature,
          type, //Предыдущий тип, пока без кастом
          sourceTitle
        }
      ]
    },
    setStamps: (state, { payload }: PayloadAction<Stamp[]>) => {
      state.stamps = payload
    },
    setSaveTrigger: (state, { payload }: PayloadAction<boolean>) => {
      state.saveTrigger = payload
    },
    setStampMode: (state, { payload }: PayloadAction<StampMode>) => {
      state.stampMode = payload
    },
    setIsSelectedQRCode: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelectedQRCode = payload
    },
    setQrCodeData: (state, { payload }: PayloadAction<string | null>) => {
      state.qrCodeData = payload
    },
    setQRCodes: (state, { payload }: PayloadAction<QRCode[]>) => {
      state.qrCodes = payload
    },
    setDocumentPages: (state, { payload }: PayloadAction<number | null>) => {
      state.documentPages = payload
    },
    setPagesForStamp: (state, { payload }: PayloadAction<number[]>) => {
      state.pagesForStamp = payload
    },
  },
})

export const { reducer: pdfViewerReducer } = pdfViewersSlice
export const {
/*   filterStamps, */
  setSelectedStamp,
 /*  changeStamp, */
  setStampSettings,
  setStamps,
  setSaveTrigger,
  setStampMode,
  setIsSelectedQRCode,
  setQrCodeData,
  setQRCodes,
  setDocumentPages,
  setPagesForStamp,
} = pdfViewersSlice.actions
