import { FC, useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FormPocketTomItemProps } from './FormPocketDrawerTomItem.types'
import {
  FormPocketDrawerTomItemButton,
  FormPocketDrawerTomItemPhase,
  FormPocketDrawerTomItemWrapper
} from './FormPocketDrawerTomItem.styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { theme } from '../../../../../../styles/theme'
import { useFormikContext } from 'formik'
import { FormPocketDrawerFormData, SelectedTomData } from '../FormPocketDrawerForm'
import { OverflowTextNew } from '@components/OverflowTextNew'


export const FormPocketDrawerTomItem: FC<FormPocketTomItemProps> = ({
  phase,
  tom,
  objectIndex,
  groupIndex,
  tomIndex,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<FormPocketDrawerFormData>()
  const { objectList, groupList, selectedToms } = formValues
  const { name, status } = tom

  const currentGroup = phase === 'РД'
    ? objectList[objectIndex]?.groups[groupIndex]
    : groupList[groupIndex]

  const selected = !!(currentGroup?.selected || currentGroup?.toms[tomIndex]?.selected)

  // set group checkbox checked when all toms checked
  useEffect(() => {
    const filteredToms = currentGroup?.toms.filter(tom => !tom.hideByFilter)

    if (filteredToms?.length && filteredToms.every(tom => tom.selected)) {
      phase === 'РД'
        ? setFieldValue(`objectList[${objectIndex}].groups[${groupIndex}].selected`, true)
        : setFieldValue(`groupList[${groupIndex}].selected`, true)

    }
  }, [currentGroup?.toms[tomIndex]])

  const onAddClick = () => {
    phase === 'РД'
      ? setFieldValue(`objectList[${objectIndex}].groups[${groupIndex}].toms[${tomIndex}].selected`, true)
      : setFieldValue(`groupList[${groupIndex}].toms[${tomIndex}].selected`, true)

    const selectedTomData: SelectedTomData = {
      tom,
      phase,
      objectIndex: phase === 'РД' ? objectIndex : undefined,
      groupIndex,
      tomIndex,
    }
    setFieldValue(`selectedToms`, [...selectedToms, selectedTomData])
  }

  // Rd
  const onRemoveClick = () => {
    if (phase === 'РД') {
      setFieldValue(`objectList[${objectIndex}].groups[${groupIndex}].toms[${tomIndex}].selected`, false)
      setFieldValue(`objectList[${objectIndex}].groups[${groupIndex}].selected`, false)
      setFieldValue(`objectList[${objectIndex}].selected`, false)
    } else {
      setFieldValue(`groupList[${groupIndex}].toms[${tomIndex}].selected`, false)
      setFieldValue(`groupList[${groupIndex}].selected`, false)
    }

    const selectedTomsCopy = [...selectedToms]
    const removedTomIndex = selectedTomsCopy.findIndex(tomData => tomData.tom.id === tom.id)
    selectedTomsCopy.splice(removedTomIndex, 1)

    setFieldValue(`selectedToms`, selectedTomsCopy)
  }

  const buttonPropsBySelectedFlag = {
    true: {
      onClick: onRemoveClick,
      startIcon: <RemoveIcon color='primary' />,
    },
    false: {
      onClick: onAddClick,
      startIcon: <AddIcon color='primary' />,
    },
  }

  return (
    <FormPocketDrawerTomItemWrapper direction='row' justifyContent='space-between' alignItems='center' spacing={1}>

        <FormPocketDrawerTomItemButton
          {...buttonPropsBySelectedFlag[`${selected}`]}
          variant='text'
        >
          <FormPocketDrawerTomItemPhase>
            <Typography>
              {phase}
            </Typography>
          </FormPocketDrawerTomItemPhase>

          <OverflowTextNew variant='subtitle2' display='inline-block !important'>
            {name || ''}
          </OverflowTextNew>
        </FormPocketDrawerTomItemButton>
      

      <Box minWidth='100px' textAlign='end'>
        <Typography
          variant='tooltip'
          textTransform='none'
          color={theme.palette.secondary.dark}
          component='p'
        >
          {status || ''}
        </Typography>
      </Box>
    </FormPocketDrawerTomItemWrapper>
  )
}
