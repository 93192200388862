import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Drawer, IconButton, Typography } from '@mui/material'
import { theme } from '../../../styles/theme'
import {
  UploadResultsButtonWrapper,
  UploadResultsDrawerTopBar,
  UploadResultsDrawerWrapper,
  UploadResultsEntireWrapper,
  UploadResultsWrapper
} from './styles'
import { UploadResultsDrawerProps } from './UploadResultsDrawer.types'
import UploadResultItem from './UploadResultItem'
import Divider from '../../Divider'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CancelIcon from '@mui/icons-material/Cancel'
import { errorTextByType, isExcelResponseForErrors } from '../../../types/global'
import { UploadResponseItem } from './UploadResultItem/UploadResultItem.types'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const UploadResultsDrawer: React.FC<UploadResultsDrawerProps> = ({
  mode,
  open,
  onClose,
  responseData
}) => {
  const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [pageScrollable, setPageScrollable] = useState<boolean>(false)

  const handleScrollButton = (direction: 'up' | 'down') => {
    if (rootElement) {
      const scrollAmount = direction === 'down' ? 200 : -200
      rootElement.scrollBy({ top: scrollAmount, behavior: 'smooth' })
    }
  }

  const listenToScroll = (element: HTMLDivElement) => {
    if (element) {
      const scrollHeight = element.scrollTop

      const height =
        element.scrollHeight -
        element.clientHeight

      const scrolled = scrollHeight / height

      setScrollPosition(scrolled)
    }
  }

  useEffect(() => {
    setScrollPosition(0)
  }, [open])

  useEffect(() => {
    rootElement?.addEventListener('scroll', () => listenToScroll(rootElement))
    if (rootElement) {
      setPageScrollable(rootElement?.scrollHeight > rootElement?.clientHeight)
    }

    return () => {
      rootElement?.removeEventListener('scroll', () => listenToScroll(rootElement))
    }
  }, [rootElement])


  const measuredRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setRootElement(node)
    }
  }, [])

  const dataForStatistics: UploadResponseItem[] | null = useMemo(() => {
    if (!responseData) return null
    return isExcelResponseForErrors(responseData)
      ? responseData.error?.map(({ type, message, row }) => ({
        title: errorTextByType[type],
        icon: <WarningAmberIcon fontSize='medium' color='error' />,
        text: message,
        optionalInfo: `Строка ${row}`
      }))
      : [
        ...responseData.failed?.map(({ fileName, description }) => ({
          title: description,
          icon: <WarningAmberIcon fontSize='medium' color='error' />,
          text: `Файл: ${fileName}`
        })),
        ...responseData.success?.map(({ fileName, description }) => ({
          title: 'Добавлен документ',
          icon: <CheckCircleIcon fontSize='medium' sx={{ color: theme.palette.legends.lightgreen }} />,
          text: `Файл: ${fileName}`
        })),
      ]
  }, [responseData])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { overflow: 'hidden' },
      }}
    >
      <UploadResultsDrawerWrapper>
        <UploadResultsDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {mode === 'single' ? 'Информация об ошибках' : 'Информация о загрузке'}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{ position: 'fixed', top: 18, right: 20, p: 0.5 }}
            disableTouchRipple
          >
            <CancelIcon color='secondary' />
          </IconButton>
        </UploadResultsDrawerTopBar>

        <Divider />

        <UploadResultsEntireWrapper ref={measuredRef}>
          {pageScrollable
            ? (
              <UploadResultsButtonWrapper
                onClick={() => scrollPosition > 0 && handleScrollButton('up')}
                style={{ top: 0, boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)' }}
                disabled={scrollPosition === 0}
              >
                <KeyboardArrowUpIcon />
              </UploadResultsButtonWrapper>
            )
            : null
          }

          {responseData
            ? (
              <UploadResultsWrapper divider={<Divider />}>
                {dataForStatistics?.map(item => (
                  <UploadResultItem data={item} />
                ))
                }
              </UploadResultsWrapper>
            )
            : null
          }


          {pageScrollable
            ? (
              <UploadResultsButtonWrapper
                onClick={() => scrollPosition < 1 && handleScrollButton('down')}
                style={{ bottom: 0, boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.05)' }}
                disabled={scrollPosition === 1}
              >
                <KeyboardArrowDownIcon />
              </UploadResultsButtonWrapper>
            )
            : null
          }

        </UploadResultsEntireWrapper>
      </UploadResultsDrawerWrapper>
    </Drawer>
  )
}

export default UploadResultsDrawer