import { Button, Drawer, Stack, Typography } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { InternalAgreementDrawerProps } from "./InternalAgreementDrawer.types"
import { DrawerBox, DrawerTopBar, DrawerWrapper } from "./InternalAgreementDrawer.styles"
import { theme } from "../../../../styles/theme"
import Divider from "../../../../components/Divider";
import { TitleDrawer } from "./components/TitleDrawer";
import { SchemeSelection } from "./components/SchemeSelection";
import { useAppDispatch, useTypedSelector } from "../../../../store/store";
import { TableFormValues, currentSchemaSelector, isOpenFormAddMemberSelector, schemaFormModeSelector, setCurrentSchema, setInitialSchemaCompanyFromMenu, setIsOpenFormAddMember, setSchemaFormMode, setTableFormValues } from "../../../../store/slices/internalAgreement";
import { HeaderSection } from "./components/HeaderSection";
import { SchemeSettings } from "./components/SchemeSettings";
import { SchemaTable } from "./components/SchemaTable";
import { useNavigate, useParams } from "react-router-dom";
import { useAddInnermapMemberMutation, useEditInnermapMemberMutation, useSendTomForInternalAgreementMutation } from "../../../../api/internalAgreement";
import { EditMemberFormValues, Innermap } from "../../../../api/internalAgreement/types";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { onDrawerClose } from "../../../../store/slices/documentsPages/drawerInfo";
import { SnackBarMessage } from "../../../../components/SnackbarMessage";
import { useBindInnermapMutation } from "../../../../api/projectAgreement";
import { currentRoadmapSelector } from "../../../../store/slices/agreement";
import { InternalAgreementForm, InternalAgreementFormValues } from "../../../Schemes/InternalAgreementSchemes/components/InternalAgreementForm";

const FORM_STYLES = {
  autocompleteWidth: '208px',
  spacing: '12px',
  minWidthForm: 'auto',
  fontSize: '12px'
}

export const InternalAgreementDrawer = ({ open, tomId, type, closeDrawer }: InternalAgreementDrawerProps) => {

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { projectId: projectIdString, stageId: stageIdString } = useParams()
  const projectId = Number(projectIdString)
  const stageId = stageIdString ? Number(stageIdString) : null
  const { enqueueSnackbar } = useSnackbar()
  const schemaFormMode = useTypedSelector(schemaFormModeSelector)
  const currentSchema = useTypedSelector(currentSchemaSelector)
  const isOpenForm = useTypedSelector(isOpenFormAddMemberSelector)
  const currentRoadmap = useTypedSelector(currentRoadmapSelector)

  const onAddSchemaClick = () => dispatch(setSchemaFormMode({ schemaFormMode: 'create' }))
  const onCancelButtonClick = () => dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))

  const [sendTomForInternalAgreement, sendTomForInternalAgreementResponse] = useSendTomForInternalAgreementMutation()
  const [addInnermapMember, addInnermapMemberResponse] = useAddInnermapMemberMutation()
  const [editInnermapMember, editInnermapMemberResponse] = useEditInnermapMemberMutation()
  const [bindInnermap, bindInnermapResponse] = useBindInnermapMutation()

  const closeForm = () => {
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
    dispatch(setTableFormValues({ tableFormValues: {} as TableFormValues }))
  }
  const addMember = (values: InternalAgreementFormValues) => {
    addInnermapMember({ projectId, innermapId: currentSchema?.id, ...values })
  }

  const editMember = (values: EditMemberFormValues) => {
    editInnermapMember({ projectId, innermapId: currentSchema?.id, ...values })
  }

  const clearFormStates = () => {
    dispatch(setCurrentSchema({} as Innermap))
    dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
    dispatch(setTableFormValues({ tableFormValues: {} as TableFormValues }))
    dispatch(setInitialSchemaCompanyFromMenu({ initialSchemaCompanyFromMenu: '' }))
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  }

  const navigateToSchemes = () => {
    clearFormStates()
    navigate(`/project/${projectId}/schemes/innermap`)
  }

  useEffect(() => {
    if (addInnermapMemberResponse.data) {
      closeForm()
      dispatch(setCurrentSchema({ ...currentSchema, members: currentSchema.members + 1 }))
    }
    if (addInnermapMemberResponse.isError) {
      enqueueSnackbar('Не удалось добавить пользователя, попробуйте еще раз.', { variant: 'error' })
    }
  }, [addInnermapMemberResponse])

  useEffect(() => {
    if (editInnermapMemberResponse.data) {
      closeForm()
    }
    if (editInnermapMemberResponse.isError) {
      enqueueSnackbar('Не удалось редактировать пользователя, попробуйте еще раз.', { variant: 'error' })

    }
  }, [editInnermapMemberResponse])

  useEffect(() => {
    if (sendTomForInternalAgreementResponse.data) {
      enqueueSnackbar(
        <SnackBarMessage
          title="Успешно"
          text="Том отправлен на внутреннее согласование"
        />,
        { variant: 'success' }
      )
      clearFormStates()
    }
    if (addInnermapMemberResponse.isError) {
      enqueueSnackbar(
        <SnackBarMessage
          title="Произошла ошибка"
          text="Том не был отправлен на внутренние согласование, попробуйте еще раз"
        />, { variant: 'error' })
    }
  }, [sendTomForInternalAgreementResponse])

  useEffect(() => {
    if (bindInnermapResponse.data) {
      enqueueSnackbar(("Схема успешно привязана к этапу согласования."), { variant: 'success' })
      clearFormStates()
    }
    if (bindInnermapResponse.isError) {
      enqueueSnackbar(
        <SnackBarMessage
          title="Произошла ошибка"
          text="Не удалось привязать схему внутреннего согласования, попробуйте еще раз"
        />, { variant: 'error' })
    }
  }, [bindInnermapResponse])

  const handleSubmit = () => {
    if (currentSchema?.id && currentSchema.isBroken === false && tomId) {
      sendTomForInternalAgreement({
        projectId,
        innermapId: currentSchema.id,
        id: tomId,
        type
      })
    }
    if (currentSchema?.id && !tomId && stageId) {
      bindInnermap({
        id: projectId,
        roadmapId: currentRoadmap.id,
        stageId,
        innermapId: currentSchema.id
      })
    }
  }
  
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => closeDrawer(!!currentSchema.id)}
    >
      <DrawerWrapper flex='1 1 0'>
        <DrawerTopBar direction='row' spacing={2}>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {tomId ? 'Отправить том на внутреннеe согласование' : 'Внутренняя процедура согласования этапа'}
          </Typography>
          {tomId &&
            <Button
              variant='text'
              sx={{ textTransform: 'none', fontWeight: '400' }}
              endIcon={<ArrowForwardIcon fontSize='medium' />}
              onClick={navigateToSchemes}>
              Перейти к шаблонам схем
            </Button>}
        </DrawerTopBar>
        <Divider />
        <Stack spacing='20px' padding='20px' flexGrow={1}>
          {schemaFormMode === 'view' &&
            <DrawerBox>
              <SchemeSelection
                isSchemaBinding={tomId === null}
                onAddSchemaClick={onAddSchemaClick} />
            </DrawerBox>}

          {(schemaFormMode === 'create' || schemaFormMode === 'edit')
            && (
              <Stack>
                <HeaderSection
                  title={schemaFormMode === 'create'
                    ? "Создать схему согласования"
                    : "Редактировать схему согласования"}
                  onButtonClick={onCancelButtonClick} />
                <DrawerBox>
                  <SchemeSettings
                    isSchemaBinding={tomId === null}
                  />
                </DrawerBox>
              </Stack>
            )}
          {(!currentSchema?.id || schemaFormMode === 'create') && (
            <TitleDrawer />)}
          {isOpenForm.isOpen &&
            <InternalAgreementForm
              closeForm={closeForm}
              addMember={addMember}
              editMember={editMember}
              variant='drawer'
              styles={FORM_STYLES}
              formMode={isOpenForm.formMode}
              innermapId={currentSchema?.id} />}
          {(currentSchema?.id && schemaFormMode !== 'create') && (
            <DrawerBox>
              <SchemaTable
                projectId={projectId}
                innermapId={currentSchema.id}
              />
            </DrawerBox>)}

          {schemaFormMode !== 'create' && (
            <Stack direction='column' justifyContent='flex-end' alignItems='flex-end' spacing={2} flex={1}>
              <Stack direction='row' spacing={2}>
                <Button
                  onClick={handleSubmit}
                  disabled={
                    !currentSchema?.id
                    || currentSchema?.isBroken !== false
                    || sendTomForInternalAgreementResponse.isLoading
                    || schemaFormMode === 'edit'
                    || isOpenForm.isOpen}
                  color='success'
                  size='small'>
                  {tomId === null ? 'Сохранить' : 'Отправить'}
                </Button>

                <Button
                  size='small'
                  onClick={() => closeDrawer(!!currentSchema.id)}>
                  Отменить и закрыть
                </Button>
              </Stack>
            </Stack>)}
        </Stack>
      </DrawerWrapper>
    </Drawer>

  )
}