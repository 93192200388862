import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import PersonIcon from '@mui/icons-material/Person'
import { Box, Stack, Typography } from '@mui/material'
import { Form, FormikProvider } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { tomIiTypes } from '../../../../api/iiPhase'
import DocumentsIcon from '../../../../assets/icons/DocumentsIcon'
import PersonWorker from '../../../../assets/icons/PersonWorkerIcon'
import Button from '../../../../components/Button'
import FieldForm from '../../../../components/FieldForm'
import FileUpload from '../../../../components/FileUpload'
import SelectFormField from '../../../../components/SelectFormField'
import useFilteredEmployees from '../../../../hooks/useFilteredEmployees'
import { useForm } from '../../../../hooks/useForm'
import { onDrawerClose, openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import {
  isExtraAccessArchitectorSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../store/slices/documentsPages/tom'
import { profileSelector } from '../../../../store/slices/profile'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ScrollableContainer } from '../../../../styles/global/ScrollableContainer'
import { theme } from '../../../../styles/theme'
import { FileUploadResponse } from '../../../../types/global'
import { TomDocument, tomStatusesArray } from '../../../../types/tom'
import { DocInfoText } from '../../../DocView/components/DocViewNavBarContentInfo/styles'
import { useGetTomsSelectData } from '../../hooks/useGetTomsSelectData'
import { useTomInitialValues } from '../../hooks/useTomInitialValues'
import { useTomSubmit } from '../../hooks/useTomSubmit'
import { DocsDrawerDatesBlock } from '../DocsDrawerFormDatesBlock'
import { DocsDrawerFormWrapper } from './DocsDrawerForm.styles'
import { DocFormData, DocsDrawerFormProps } from './DocsDrawerForm.types'
import { getTomValidation } from './DocsDrawerForm.validation'

export const DocsDrawerForm: React.FC<DocsDrawerFormProps> = ({
  // doc,
  onFormChange,
  onClose,
  uploadData,
  autocompleteData,
  permanentAutocompleteData,
  onSuccessCreate,
  otherPhase,
  otherProjectID,
}) => {
  const dispatch = useAppDispatch()

  const { role } = useTypedSelector(profileSelector)
  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)

  const projectPhase = otherPhase || selectedProjectPhase
  const isEditTom = openedDrawer === 'update' && !!tom?.id

  const { description, status } = (isEditTom && tom) || {}

  const { architectorsAndAdmins, clientsAndAdmins, contractors } = useFilteredEmployees()
  const { autocompleteInitialValues, contractorInitialValues, dateInitialValues } = useTomInitialValues({
    autocompleteData,
    otherPhase,
    otherProjectID,
  })

  const initialValues: DocFormData = useMemo(
    () => ({
      ...autocompleteInitialValues,
      ...contractorInitialValues,
      ...dateInitialValues,
      description: description || '',
      status: status || '',
      files: null,
    }),
    [
      tom,
      autocompleteInitialValues,
      contractorInitialValues,
      dateInitialValues,
      architectorsAndAdmins,
      clientsAndAdmins,
      contractors,
    ],
  )

  const [responseData, setResponseData] = useState<FileUploadResponse<TomDocument> | undefined>(undefined)

  const onDrawerCloseWithSetResData = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      onClose?.(dirty, immediately)
      dispatch(onDrawerClose({ dirty, immediately }))
      setResponseData(undefined)
    },
    [onDrawerClose],
  )

  const validationTom = useMemo(() => {
    return getTomValidation(uploadData.formats, projectPhase)
  }, [uploadData, projectPhase])

  const { onSubmit, fileLoading } = useTomSubmit({
    setResponseData,
    onDrawerClose: onDrawerCloseWithSetResData,
    onSuccessCreate,
    otherPhase,
    otherProjectID,
  })

  const { formik } = useForm({
    validationSchema: validationTom,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values)
  })
  const { values, dirty } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useEffect(() => {
    setResponseData(undefined)
  }, [values.files])

  const { tomSelectsData } = useGetTomsSelectData({
    otherPhase,
    otherProjectID,
    permanentAutocompleteData,
  })

  return (
    <DocsDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} overflow='hidden' height='100%' component={Form}>
          <ScrollableContainer spacing={2.5}>
            <Stack spacing={1.5}>
              <FieldForm version='doc' name='title' placeholder='Шифр РД*' />
              <FieldForm
                version='doc'
                name='description'
                placeholder='Укажите полное название тома*'
                multiline
                minRows={2}
              />
            </Stack>

            <Stack spacing={1.5}>
              {projectPhase === 'Инженерные изыскания' && (
                <SelectFormField<number | string, DocFormData>
                  fieldName='iiType'
                  title='Тип документа'
                  placeholder='Выберите тип'
                  icon={DocumentsIcon}
                  data={tomIiTypes.map((type) => ({
                    value: type,
                    label: type,
                    key: type,
                  }))}
                  autocompleteProps={{ autocomplete: true, freeSolo: false }}
                />
              )}

              {role === 'admin' || status !== 'Согласовано' ? (
                <SelectFormField<string, DocFormData>
                  fieldName='status'
                  title='Статус'
                  required
                  placeholder='Выберите статус'
                  icon={ErrorOutlineIcon}
                  data={tomStatusesArray
                    .filter((status) => {
                      if (status === 'Не согласуется')
                        return (
                          projectPhase === 'Сбор исходных данных' &&
                          (role === 'admin' || role === 'architector' || role === 'client')
                        )
                      if (status === 'Согласовано')
                        return role === 'admin' || role === 'client' || isExtraAccessArchitector
                      return true
                    })
                    .map((status) => ({
                      value: status,
                      label: status,
                      key: status,
                    }))}
                  autocompleteProps={{ autocomplete: true, freeSolo: false }}
                />
              ) : (
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
                  <Stack direction='row' spacing={1}>
                    <ErrorOutlineIcon fontSize='medium' color='secondary' />
                    <Typography variant='body2' component='span'>
                      Статус:
                    </Typography>
                  </Stack>

                  <DocInfoText variant='subtitle2' style={{ color: theme.palette.primary.main }}>
                    {status}
                  </DocInfoText>
                </Stack>
              )}

              {tomSelectsData?.map(({ permanentValue, fieldName, title, placeholder, icon, data }) => (
                <SelectFormField<number, DocFormData>
                  fieldName={fieldName}
                  title={title}
                  required
                  placeholder={placeholder}
                  icon={icon}
                  data={data}
                  autocompleteProps={{ autocomplete: true, freeSolo: false, permanentValue }}
                />
              ))}

              <SelectFormField<number | string, DocFormData>
                fieldName='architectorData'
                title='Проектировщик'
                placeholder='Выберите из списка'
                icon={PersonWorker}
                data={architectorsAndAdmins.map(({ id, name }) => ({
                  value: id,
                  label: name,
                  key: id,
                }))}
                autocompleteProps={{ autocomplete: true, freeSolo: true }}
              />

              {projectPhase === 'Рабочая документация' ? (
                <SelectFormField<number, DocFormData>
                  fieldName='contractorData'
                  title='Подрядчик'
                  placeholder='Выберите из списка'
                  icon={PersonIcon}
                  data={contractors.map(({ id, name }) => ({
                    value: id,
                    label: name,
                    key: id,
                  }))}
                  autocompleteProps={{ autocomplete: true, freeSolo: true }}
                />
              ) : null}

              <SelectFormField<number, DocFormData>
                fieldName='clientData'
                title='Заказчик'
                placeholder='Выберите из списка'
                icon={PersonIcon}
                data={clientsAndAdmins.map(({ id, name }) => ({
                  value: id,
                  label: name,
                  key: id,
                }))}
                autocompleteProps={{ autocomplete: true, freeSolo: true }}
              />
            </Stack>

            <Stack spacing={2}>
              <Box>
                <Typography variant='body2'>Даты согласования:</Typography>
              </Box>

              <DocsDrawerDatesBlock />
            </Stack>

            {!isEditTom ? (
              <FileUpload<TomDocument>
                name='files'
                mode='single'
                uploadData={uploadData}
                loading={fileLoading}
                responseData={responseData}
              />
            ) : null}
          </ScrollableContainer>

          <Stack direction='row' spacing={2} pr={2.5}>
            <Button type='submit' disabled={fileLoading} color='success' size='medium' fullWidth>
              Сохранить
            </Button>
            <Button size='medium' fullWidth onClick={() => onDrawerCloseWithSetResData(false, true)}>
              Отменить
            </Button>
          </Stack>
        </Stack>
      </FormikProvider>
    </DocsDrawerFormWrapper>
  )
}
