import { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { ExportArchiveAccordionProps } from './ExportArchiveAccordion.types'
import {
  StyledExportArchiveAccordion,
  StyledExportArchiveAccordionDetails,
  StyledExportArchiveAccordionSummary
} from './styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FieldForm from '../../../../../../components/FieldForm'
import { Typography } from '@mui/material'
import { useAccordionFieldData } from './hooks/useAccordionFieldData'
import { useAccordionOpenState } from './hooks/useAccordionOpenState'
import { useAccordionCheckState } from './hooks/useAccordionCheckState'
import { useRenderAccordionDetails } from './hooks/useRenderAccordionDetails'
import { OverflowTextNew } from '@components/OverflowTextNew'

export const ExportArchiveAccordion: FC<ExportArchiveAccordionProps> = ({
  object,
  pd,
  ii,
  ird,
  objectIndex,
  pdIndex,
  iiIndex,
  irdIndex,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const { accordionLabel, accordionFieldName, accordionFieldChecked, setOpenedManually } = useAccordionFieldData({
    object,
    pd,
    ii,
    ird,
    objectIndex,
    pdIndex,
    iiIndex,
    irdIndex,
    expanded,
  })

  const onSummaryClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setExpanded(prevState => !prevState)
    setOpenedManually()
  }

  useAccordionOpenState({ objectIndex, pdIndex, iiIndex, irdIndex, setExpanded })

  const {
    onAccordionCheckboxChange,
    setCheckedRd,
    getUpdatedSelectedRdToms,
    deleteTomsFromSelected,
    disableExpandIcon,
    accordionHideByFilter,
  } = useAccordionCheckState({
    object,
    pd,
    ii,
    ird,
    objectIndex,
    pdIndex,
    iiIndex,
    irdIndex,
  })

  const { accordionDetails } = useRenderAccordionDetails({
    object,
    pd,
    ii,
    ird,
    objectIndex,
    pdIndex,
    iiIndex,
    irdIndex,
    setCheckedRd,
    getUpdatedSelectedRdToms,
    deleteTomsFromSelected,
  })

  if (accordionHideByFilter) return null

  return (
    <StyledExportArchiveAccordion
      expanded={expanded}
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
    >
      <StyledExportArchiveAccordionSummary
        onClick={(e) => onSummaryClick(e)}
        expandIcon={!disableExpandIcon && (
          <ExpandMoreIcon
            fontSize='medium'
            color='primary'
          />
        )}
      >
        <FieldForm
          version='checkbox'
          name={accordionFieldName}
          checkboxData={{
            label: (<OverflowTextNew variant='subtitle2'>{accordionLabel}</OverflowTextNew>),
            checked: accordionFieldChecked,
            onChange: (e: ChangeEvent<HTMLInputElement>) => onAccordionCheckboxChange(e, accordionFieldName),
            onLabelClick: (e) => e.stopPropagation(),
          }}
        />
      </StyledExportArchiveAccordionSummary>

      <StyledExportArchiveAccordionDetails>
        {accordionDetails}
      </StyledExportArchiveAccordionDetails>
    </StyledExportArchiveAccordion>
  )
}
