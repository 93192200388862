import { UseDownloadFilesResponse } from './useDownloadFiles.types'
import { useLazyGetLinkForDownloadTomRdFilesQuery } from '../../../../../../api/rdPhase'
import { useLazyGetLinkForDownloadTomPdFilesQuery } from '../../../../../../api/pdPhase'
// @ts-ignore:next-line
import { useLazyGetLinkForDownloadTomIiFilesQuery } from '../../../../../../api/iiPhase/tom/archiveExport'
import { useTypedSelector } from '../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../../../store/slices/documentsPages/tom'
import { useLazyGetLinkForDownloadTomIrdFilesQuery } from '../../../../../../api/irdPhase/tom/archiveExport'

export const useDownloadFiles = (): UseDownloadFilesResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)


  // Rd
  const [getRdLink] = useLazyGetLinkForDownloadTomRdFilesQuery()

  // Pd
  const [getPdLink] = useLazyGetLinkForDownloadTomPdFilesQuery()

  // Ii
  const [getIiLink] = useLazyGetLinkForDownloadTomIiFilesQuery()

  // Ird
  const [getIrdLink] = useLazyGetLinkForDownloadTomIrdFilesQuery()

  // Common
  const onDownloadTomFilesClick = () => {
    if (!tom?.id) {
      console.log('Ошибка: нет id тома')
      return
    }
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        getRdLink({ id: tom.id })
          .then((res) => res.data?.link && window.open(res.data.link))
          .catch((err) => console.log(err))
        break
      case 'Проектная документация':
        getPdLink({ id: tom.id })
          .then((res) => res.data?.link && window.open(res.data.link))
          .catch((err) => console.log(err))
        break
      case 'Инженерные изыскания':
        getIiLink({ id: tom.id })
          .then((res) => res.data?.link && window.open(res.data.link))
          .catch((err) => console.log(err))
        break
      case 'Сбор исходных данных':
        getIrdLink({ id: tom.id })
          .then((res) => res.data?.link && window.open(res.data.link))
          .catch((err) => console.log(err))
        break
    }
  }

  return {
    onDownloadTomFilesClick,
  }
}
