import {
  GetLinkForDownloadTomIrdFilesRequest,
  GetLinkForDownloadTomIrdFilesResponse,
  GetTomsIrdFileListRequest,
  GetTomsIrdFileListResponse,
  SendTomsIrdFileListRequest,
  SendTomsIrdFileListResponse
} from './archiveExportApi.types'
import { tomIrdApi } from '../tomApi'


export const archiveExportIrdApi = tomIrdApi.injectEndpoints({
  endpoints: (build) => ({
    getTomsIrdFileList: build.query<GetTomsIrdFileListResponse, GetTomsIrdFileListRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-ird/download/get-file-list`,
        method: 'GET',
        params
      }),
      providesTags: [{ type: 'TomIrd', id: 'ARCHIVE_TOMS' }],
    }),
    sendTomsIrdFileList: build.mutation<SendTomsIrdFileListResponse, SendTomsIrdFileListRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/tom-ird/download/create-download-link`,
        method: 'POST',
        body,
      }),
    }),
    getLinkForDownloadTomIrdFiles: build.query<GetLinkForDownloadTomIrdFilesResponse, GetLinkForDownloadTomIrdFilesRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom-ird/${id}/create-download-link`,
        method: 'GET',
        params
      }),
      providesTags: [
        'TomIrd',
        { type: 'TomIrd', id: 'UPLOAD' },
        { type: 'TomIrd', id: 'DELETE' },
        { type: 'TomIrd', id: 'UPLOAD_MASS' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomsIrdFileListQuery,
  useSendTomsIrdFileListMutation,
  useLazyGetLinkForDownloadTomIrdFilesQuery,
} = archiveExportIrdApi
