import {
  GetLinkForDownloadTomRdFilesRequest,
  GetLinkForDownloadTomRdFilesResponse,
  GetTomsRdFileListRequest,
  GetTomsRdFileListResponse,
  SendTomsRdFileListRequest,
  SendTomsRdFileListResponse
} from './archiveExportApi.types'
import { tomRdApi } from '../tomApi'


export const archiveExportRdApi = tomRdApi.injectEndpoints({
  endpoints: (build) => ({
    getTomsRdFileList: build.query<GetTomsRdFileListResponse, GetTomsRdFileListRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom/download/get-file-list`,
        method: 'GET',
        params
      }),
      providesTags: [{ type: 'TomRd', id: 'ARCHIVE_TOMS' }],
    }),
    sendTomsRdFileList: build.mutation<SendTomsRdFileListResponse, SendTomsRdFileListRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/tom/download/create-download-link`,
        method: 'POST',
        body,
      }),
    }),
    getLinkForDownloadTomRdFiles: build.query<GetLinkForDownloadTomRdFilesResponse, GetLinkForDownloadTomRdFilesRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom/${id}/create-download-link`,
        method: 'GET',
        params
      }),
      providesTags: [
        'TomRd',
        { type: 'TomRd', id: 'UPLOAD' },
        { type: 'TomRd', id: 'DELETE' },
        { type: 'TomRd', id: 'UPLOAD_MASS' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomsRdFileListQuery,
  useSendTomsRdFileListMutation,
  useLazyGetLinkForDownloadTomRdFilesQuery,
} = archiveExportRdApi
