import styled from 'styled-components'
import { Button, Stack } from '@mui/material'

export const ExportArchiveTomItemWrapper = styled(Stack)`
  width: 100%;
`

export const ExportArchiveTomItemButton = styled(Button)`
  justify-content: start;
  
  & .MuiTypography-root {
    margin-top: 3px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.text.dark};
    text-overflow: ellipsis;
    overflow: hidden;
  }
`