import React, { useCallback, useMemo, useState } from 'react'
import { FormControl, Stack, Typography } from '@mui/material'
import { DisabledSelectMenuItem, StyledSelectMenuItem } from '../UserRoleSelection/styles'
import { useGetProjectsQuery } from '../../../../api/projects'
import { GetProjectsResponse } from '../../../../api/projects/api.types'
import { theme } from '../../../../styles/theme'
import AddIcon from '@mui/icons-material/Add'
import Button from '../../../Button'
import AccessCard from '../AccessCard'
import { UserAccessesSelectionProps } from './UserAccessesSelection.types'
import { AccessStatusBlock } from '../../UserForm/UserAccesses/styles'
import FieldForm from '../../../FieldForm'
import { SelectMenuProps } from '../UserObjectsSelection'
import { FieldArray, useFormikContext } from 'formik'
import { UserFormData } from '../../UserForm/UserForm.types'


const UserAccessesSelection: React.FC<UserAccessesSelectionProps> = ({
  contractor,
  children,
  disabled = false
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<UserFormData>()
  const [selectHidden, setSelectHidden] = useState<boolean>(!!formValues.projects?.length)
  const [open, setOpen] = useState<boolean>(false)

  const { data, isFetching } = useGetProjectsQuery({})
  const { data: projects } = data || ({} as GetProjectsResponse)

  const projectForSelect = useMemo(() => {
    if (projects) {
      const copy = [...projects]
      formValues?.projects?.forEach(project => {
        const selectedProjectId = copy.findIndex(rootProject => rootProject.id === project.id)
        copy.splice(selectedProjectId, 1)
      })

      return copy
    }
  }, [projects, formValues.projects])

  const onAddClick = useCallback(() => {
    setSelectHidden(false)
    setOpen(true)
  }, [])

  const onSelectOpen = useCallback(() => {
    setOpen(true)
    setSelectHidden(false)
  }, [])

  const onSelectClose = useCallback(() => {
    setOpen(false)
    if (formValues?.projects?.length) {
      setSelectHidden(true)
    }
  }, [formValues.projects])

  const isDataLoading = isFetching

  if (children) {
    return <>{children}</>
  }

  return (
    <Stack alignItems='flex-end' spacing={4.375}>
      <FieldArray
        name='projects'
        validateOnChange={false}
        render={({ push, remove }) => {
          return (
            <>
              {formValues.projects.map((project, index) => (
                <AccessCard
                  projectData={{ id: project.id, name: project.name }}
                  index={index}
                  onAccessItemDelete={() => remove(index)}
                  key={project.id}
                />
              ))}

              {projectForSelect?.length
                ? (
                  formValues.projects.length && selectHidden
                    ? (
                      <Button
                        startIcon={<AddIcon />}
                        variant='text'
                        bgColor={theme.palette.bg.shades}
                        onClick={onAddClick}
                        style={{ maxWidth: '276px', width: '100%', height: '44px' }}
                        sx={{
                          '& .MuiButton-startIcon': { paddingTop: '1px' }
                        }}
                      >
                        Добавить проект
                      </Button>
                    )
                    : (
                      <FormControl
                        style={{ width: '100%', maxWidth: '276px' }}
                        sx={{
                          '& .MuiSelect-outlined': {
                            padding: '10px 16px !important',
                            fontSize: '16px !important',
                            lineHeight: '23px !important',
                            textAlign: 'left !important',
                            color: `${theme.palette.text.dark} !important`
                          },
                          ' & .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid rgba(92, 110, 140, 0.3) !important',
                            borderRadius: '6px !important'
                          },
                          '& .MuiSvgIcon-root': {
                            right: '14px !important'
                          }
                        }}
                      >
                        <FieldForm
                          disabled={disabled}
                          version='select'
                          name='helper'
                          onChange={(e) => {
                            setSelectHidden(true)
                            const value: string | 'all' = e.target.value
                            if (!contractor && value === 'all') {
                              setFieldValue('projects', projects.map(project => ({
                                id: project.id,
                                name: project.shifrName,
                                objects: [],
                                allObjects: true,
                                extraAccess: formValues.role === 'architector' ? true : undefined,
                              })))
                              return
                            }

                            const projectName = projectForSelect?.find(project => project.id === parseInt(value))?.shifrName
                            push({
                              allObjects: !contractor,
                              id: parseInt(value),
                              name: projectName,
                              objects: [],
                              extraAccess: formValues.role === 'architector' ? true : undefined,
                            })
                          }}

                          SelectProps={{
                            open: open,
                            onOpen: onSelectOpen,
                            onClose: onSelectClose,
                            MenuProps: SelectMenuProps,
                            value: 'none',
                          }}
                        >
                          <DisabledSelectMenuItem
                            value='none'
                            disabled
                          >
                            Выберите проект
                          </DisabledSelectMenuItem>
                          {!contractor
                            ? (
                              <StyledSelectMenuItem
                                value='all'
                              >
                                Все проекты
                              </StyledSelectMenuItem>
                            )
                            : null
                          }
                          {!isDataLoading && (
                            projectForSelect.map(project => (
                              <StyledSelectMenuItem
                                value={project.id}
                                key={project.id}
                              >
                                {project.shifrName}
                              </StyledSelectMenuItem>
                            ))
                          )}
                        </FieldForm>
                      </FormControl>
                    )
                )
                : (
                  <AccessStatusBlock color={theme.palette.text.dark}>
                    <Typography variant='body1'>Проекты отсутствуют</Typography>
                  </AccessStatusBlock>
                )
              }
            </>
          )
        }}
      />
    </Stack>
  )
}

export default UserAccessesSelection